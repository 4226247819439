import { Component, OnInit } from '@angular/core';
import {ReviewsService} from '../shared/services/reviews.service';
import {CmsService} from "../shared/services/cms.service";

@Component({
  selector: 'mbnm-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private reviewsService: ReviewsService, private cms: CmsService) { }

  currentYear: number;
  tpReviewSummary: any;
  rrReviewSummary: any;
  menuItems = [];
  async ngOnInit() {
    this.currentYear = new Date().getFullYear();
    const results = await this.reviewsService.getAggregateInfo()
    this.tpReviewSummary = results.find(r => r.provider === 'TrustPilot');
    this.rrReviewSummary = results.find(r => r.provider === 'ResellerRatings');
    this.menuItems = await this.cms.menu('footer');
  }

}
