
import {Inject, Injectable, OnDestroy, Optional} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import {Observable, Subject, takeUntil} from 'rxjs';
import {environment} from '../environments/environment';
import {REQUEST} from "../express.tokens";

export class BaseInterceptor {
  public static doInterception (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // only apply interceptor to api calls
    if (req.url.indexOf(environment.baseUrls().api) === 0) {
      return next.handle(req.clone({
        withCredentials: true
      }));
    } else {
      return next.handle(req);
    }
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {
  private destroy$ = new Subject<void>();
  constructor() { }

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // only apply interceptor to api calls
    return BaseInterceptor
      .doInterception(req, next)
      .pipe(takeUntil(this.destroy$));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

@Injectable()
export class UniversalAuthInterceptor implements HttpInterceptor, OnDestroy {
  private destroy$ = new Subject<void>();
  constructor(@Optional() @Inject(REQUEST) private request) { }

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let serverReq: HttpRequest<any> = req;
    if (this.request && req.url.indexOf('http') !== 0) {
      let newUrl = `${this.request.protocol}://${this.request.get('host')}`;
      if (!req.url.startsWith('/')) {
        newUrl += '/';
      }
      newUrl += req.url;
      serverReq = req.clone({url: newUrl, withCredentials: true});
    }
    return BaseInterceptor
      .doInterception(serverReq, next)
      .pipe(takeUntil(this.destroy$));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
