
<!-- content -->
<section class="row">
  <div class="container">
    <div class="col s12">
      <h1>Unsubscribe</h1>

      <p *ngIf="status=='IN_PROGRESS'">
        Please wait while we unsubscribe you.
        <mat-progress-spinner mode="indeterminate" *ngIf="status=='IN_PROGRESS'" color="secondary">

        </mat-progress-spinner>
      </p>

      <p *ngIf="status=='DONE'">
        You have been successfully unsubscribed from the {{listName}}.
      </p>
      <p *ngIf="error?.ErrorCode == 'SUBSCRIPTIONS_EMAIL_NOT_FOUND'">
        The supplied email is not currently registered for {{listName}}. <b>No action was taken.</b>
      </p>
      <p *ngIf="error?.ErrorCode == 'SUBSCRIPTIONS_UNKNOWN_ERROR'">
        There was an error un-subscribing from the {{listName}}.
        Error Details: <b>{{ error?.Message }}.</b>
      </p>
      <p>
        Sincerely
      </p>
      <p>
        Matboard and More
      </p>
    </div>
  </div>
</section>
<!-- // content -->
