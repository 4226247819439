import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {UnsubscribeComponent} from "./unsubscribe/unsubscribe.component";

const routes: Routes = [
  // home page
  {path: '', pathMatch: 'full', loadChildren: () => import('./index/index.module').then(m => m.IndexModule)},

  // various contact us pages
  {path: 'contact-us', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule)},
  {path: 'custom-matboard-design-quote', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule)},
  {path: 'matboard-awards', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule)},

  // cart pages
  {path: 'cart', loadChildren: () => import('./cart/cart.module').then(m => m.CartModule)},
  {path: 'order', loadChildren: () => import('./cart/cart.module').then(m => m.CartModule)},
  {path: 'checkout', loadChildren: () => import('./cart/cart.module').then(m => m.CartModule)},
  {path: 'confirmation', loadChildren: () => import('./cart/cart.module').then(m => m.CartModule)},

  // acount
  {path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule)},

  // help pages
  {path: 'help', loadChildren: () => import('./help/help.module').then(m => m.HelpModule)},

  // builder pages
  {path: 'buy', loadChildren: () => import('./builder/builder.module').then(m => m.BuilderModule)},
  {path: 'edit', loadChildren: () => import('./builder/builder.module').then(m => m.BuilderModule)},

  // price list pages
  {path: 'price-list/:category', loadChildren: () => import('./price-list/price-list.module').then(m => m.PriceListModule)},

  // deals pages
  {path: 'deals', loadChildren: () => import('./deals/deals.module').then(m => m.DealsModule)},

  // unsub page
  {path: 'unsubscribe', component: UnsubscribeComponent},

  // page not found (404)
  {path: 'not-found', component: PageNotFoundComponent},

  /* default all other paths to CMS module */
  {path: '', loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledNonBlocking' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
