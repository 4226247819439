import {Inject, Injectable} from '@angular/core';
import {WINDOW} from './shared/services/window.service';
import {Angulartics2} from 'angulartics2';
import {SessionService} from './shared/services/session.service';
import {User} from './shared/models/user';
import {Subscription} from "rxjs";


@Injectable({ providedIn: 'root' })
export class Angulartics2Impact {

  private currentUser: any;
  private currentUserChangedSubRef: Subscription;
  private pageTrackSubRef: Subscription;

  constructor(
    private angulartics2: Angulartics2,
    @Inject(WINDOW) private window: Window
  ) {
    this.currentUserChangedSubRef = SessionService.currentUserChanged.subscribe((currentUser:User) => {
      this.currentUser = currentUser;
    });
  }

  stopTracking(): void {
    if (this.currentUserChangedSubRef) {
      this.currentUserChangedSubRef.unsubscribe();
    }

    if (this.pageTrackSubRef) {
      this.pageTrackSubRef.unsubscribe();
    }
  }

  startTracking(): void {
    this.pageTrackSubRef =  this.angulartics2.pageTrack
      .pipe(this.angulartics2.filterDeveloperMode())
      .subscribe((x) => this.pageTrack(x.path));
  }

  pageTrack(path: string) {
    const ire = this.window['ire'];
    if (ire) {
      const u = this.currentUser;
      ire('identify', {
        customerId: u && u.publicId && u.encryptedEmail ? u.publicId : '',
        customerEmail: u && u.encryptedEmail ? u.encryptedEmail : ''
      });
    }
  }
}
