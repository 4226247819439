import {Component, Inject, OnInit, Optional} from '@angular/core';
import {RESPONSE} from '../../express.tokens';

@Component({
  selector: 'mbnm-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(@Optional() @Inject(RESPONSE) private response: any) { }

  ngOnInit() {
    if (this.response) {
      this.response.statusCode = 404;
      this.response.statusMessage = '404 - Page Not Found';
    }
  }
}
