<div class="container"
     [ngClass]="{builder:inBuilder,'show-banner': dynamicTextBlocks?.banner, 'black-friday': dynamicTextBlocks?.black_friday_theming}">

  <mbnm-header [currentCart]="currentCart" [currentUser]="currentUser" [dynamicTextBlocks]="dynamicTextBlocks"> </mbnm-header>

  <div class="content-container">
    <router-outlet></router-outlet>
  </div>

  <mbnm-footer> </mbnm-footer>

  <div class="unfinished-work fx-layout-row fx-align--space-between-x" *ngIf="!unfinishedProjectNotificationHidden && !ineligibleOrderline && !inShoppingProcess && !inCheckoutProcess">
    <div class="fx-layout-row fx-align--x-center fx-gap--16px">
      <button mat-icon-button color="secondary" (click)="hideUnfinishedProjectNotification()">
        <mat-icon svgIcon="x"></mat-icon>
      </button>
      <span>
        You Have An Unfinished Project
      </span>
    </div>
    <button mat-flat-button="" color="primary" routerLink="/buy/unfinished-order">
      <span>
        Continue <mat-icon svgIcon="chevron-right"></mat-icon>
      </span>
    </button>
  </div>
</div>
